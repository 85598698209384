var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-6 pa-2"},[(_vm.items.length)?_c('v-data-table',{staticClass:"ma-3 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"dense":""},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(_vm._s(_vm.truncate(item.title, 100)))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDate(item.date)))])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTime(item.date)))])]}}],null,false,878557395)}):_c('span',[_vm._v(_vm._s(_vm.noDataText))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }